import _Popper2 from "./Popper";
import _Manager2 from "./Manager";
import _Reference2 from "./Reference";
import _usePopper2 from "./usePopper";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Popper", {
  enumerable: true,
  get: function get() {
    return _Popper.Popper;
  }
});
Object.defineProperty(exports, "Manager", {
  enumerable: true,
  get: function get() {
    return _Manager.Manager;
  }
});
Object.defineProperty(exports, "Reference", {
  enumerable: true,
  get: function get() {
    return _Reference.Reference;
  }
});
Object.defineProperty(exports, "usePopper", {
  enumerable: true,
  get: function get() {
    return _usePopper.usePopper;
  }
});
var _Popper = _Popper2;
var _Manager = _Manager2;
var _Reference = _Reference2;
var _usePopper = _usePopper2;
export default exports;
export const __esModule = exports.__esModule,
      Popper = exports.Popper,
      Manager = exports.Manager,
      Reference = exports.Reference,
      usePopper = exports.usePopper;